import { render, staticRenderFns } from "./403.vue?vue&type=template&id=56287b72&scoped=true&"
import script from "./403.vue?vue&type=script&lang=js&"
export * from "./403.vue?vue&type=script&lang=js&"
import style0 from "./403.vue?vue&type=style&index=0&id=56287b72&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56287b72",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\jenkinsworkspace\\Aigo.B2CAdmin.Publish\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56287b72')) {
      api.createRecord('56287b72', component.options)
    } else {
      api.reload('56287b72', component.options)
    }
    module.hot.accept("./403.vue?vue&type=template&id=56287b72&scoped=true&", function () {
      api.rerender('56287b72', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/page/403.vue"
export default component.exports